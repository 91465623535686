@import './breakpoints.scss';

/*
* font variables
*/

$upper-caption-s-font-size: 10px;
$upper-caption-m-font-size: 12px;
$upper-caption-s-line-height: 110%;
$upper-caption-m-line-height: 130%;

$body-s-font-size: 12px;
$body-base-font-size: 14px;
$body-s-line-height: 130%;
$body-base-line-height: 150%;

$heading-1-font-size: 24px;
$heading-2-font-size: 20px;
$heading-3-font-size: 17px;
$heading-1-line-height: 130%;
$heading-2-line-height: 130%;
$heading-3-line-height: 130%;

$action-s-font-size: 12px;
$action-m-font-size: 14px;
$action-s-line-height: 130%;
$action-m-line-height: 130%;

$font-weight-regular: normal;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: bold;

/*
* fonts
*/

/* Inter Regular */
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Regular.woff2') format('woff2'),
  url('../fonts/Inter/Inter-Regular.woff') format('woff'),
  url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: $font-weight-regular;
  font-style: normal;
}

/* Inter Medium */
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Medium.woff2') format('woff2'),
  url('../fonts/Inter/Inter-Medium.woff') format('woff'),
  url('../fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: $font-weight-medium;
  font-style: normal;
}

/* Inter Medium Italic */
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500; /* Medium */
  src: url('../fonts/Inter/Inter-MediumItalic.otf') format('opentype');
}

/* Inter SemiBold */
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: $font-weight-semibold;
  font-style: normal;
}

/* Inter Bold */
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Bold.woff2') format('woff2'),
  url('../fonts/Inter/Inter-Bold.woff') format('woff'),
  url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: $font-weight-bold;
  font-style: normal;
}


/*
* headings
*/

@mixin typography($font, $fontSize, $lineHeight, $fontWeight, $link: false) {
  font-family: $font, sans-serif;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $fontWeight;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @if $link {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

/*
* body
*/

@mixin body-s($fontWeight, $italic: false) {
  @include typography('Inter', $body-s-font-size, $body-s-line-height, $fontWeight);

  letter-spacing: -0.24px;

  @if $italic {
    font-style: italic;
  }
}

@mixin body-s-landing($fontWeight: $font-weight-medium) {
  @include typography('Inter', 14px, 130%, $fontWeight);

  letter-spacing: -0.28px;
}

@mixin body-l($fontWeight: $font-weight-regular) {
  @include typography('Inter', 18px, 130%, $fontWeight);
  letter-spacing: -0.36px;

  @include tablet {
    @include typography('Inter', 22px, 130%, $fontWeight);
    letter-spacing: -0.44px;
  }

  @include desktop {
    @include typography('Inter', 24px, 130%, $fontWeight);
    letter-spacing: -0.48px;
  }
}

@mixin body-base($fontWeight, $link: false) {
  @include typography('Inter', $body-base-font-size, $body-base-line-height, $fontWeight, $link);

  letter-spacing: -0.28px;
}

@mixin body-base-landing($fontWeight: $font-weight-medium) {
  @include typography('Inter', 16px, 130%, $fontWeight);

  letter-spacing: -0.32px;
}

/*
* headings
*/

@mixin h1($fontWeight: $font-weight-semibold) {
  @include typography('Inter', $heading-1-font-size, $heading-1-line-height, $fontWeight);

  letter-spacing: -0.48px;
}

@mixin h2($fontWeight) {
  @include typography('Inter', $heading-2-font-size, $heading-2-line-height, $fontWeight);

  letter-spacing: -0.4px;
}

@mixin h1-landing() {
  @include typography('Inter', 55px, 130%, $font-weight-bold);
  letter-spacing: -2.75px;

  @include tablet {
    @include typography('Inter', 84px, 100%, $font-weight-bold);
    letter-spacing: -4.2px;
  }

  @include desktop {
    @include typography('Inter', 100px, 100%, $font-weight-bold);

    letter-spacing: -5px;
  }
}

@mixin h2-landing($fontWeight) {
  @include typography('Inter', 35px, 130%, $fontWeight);
  letter-spacing: -0.7px;

  @include tablet {
    @include typography('Inter', 52px, 100%, $fontWeight);
    letter-spacing: -1px;
  }

  @include desktop {
    @include typography('Inter', 60px, 100%, $fontWeight);

    letter-spacing: -1.2px;
  }
}

@mixin h3-landing($fontWeight) {
  @include typography('Inter', 24px, 120%, $fontWeight);
  letter-spacing: -0.48px;

  @include tablet {
    @include typography('Inter', 32px, 120%, $fontWeight);
    letter-spacing: -0.64px;
  }

  @include desktop {
    @include typography('Inter', 40px, 120%, $fontWeight);

    letter-spacing: -0.8px;
  }
}

@mixin h3($fontWeight) {
  @include typography('Inter', $heading-3-font-size, $heading-3-line-height, $fontWeight);

  letter-spacing: -0.34px;
}

/*
* captions
*/

@mixin upper-caption-s {
  @include typography('Inter', $upper-caption-s-font-size, $upper-caption-s-line-height, $font-weight-semibold);
  text-transform: uppercase;
}

@mixin upper-caption-m {
  @include typography('Inter', $upper-caption-m-font-size, $upper-caption-m-line-height, $font-weight-semibold);
  text-transform: uppercase;
}

/*
* actions
*/

@mixin action-s {
  @include typography('Inter', $action-s-font-size, $action-s-line-height, $font-weight-bold);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

@mixin action-m($hovered: true) {
  @include typography('Inter', $action-m-font-size, $action-m-line-height, $font-weight-bold);

  @if $hovered {
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      text-decoration: underline;
    }
  }
}

// Career page
@mixin h1-cp() {
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-weight: $font-weight-bold;
  line-height: 120%;

  @include desktop {
    font-size: 60px;
  }
}

@mixin h2-cp() {
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-weight: $font-weight-bold;
  line-height: 120%;

  @include desktop {
    font-size: 36px;
  }
}

@mixin h3-cp() {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: $font-weight-medium;
  line-height: 120%;

  @include desktop {
    font-size: 28px;
  }
}

@mixin body-l-cp($fontWeight) {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: $fontWeight;
  line-height: 150%;

  @include desktop {
    font-size: 20px;
  }
}

@mixin body-base-cp($fontWeight) {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: $fontWeight;
  line-height: 150%;
}

// clear default styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0;
}
