@use '../../../node_modules/@angular/material/index' as mat;
@import '../../styles/global/colours.scss';
@import '../../styles/global/typography.scss';
@import '../../styles/global/colours.scss';
@import '../../styles/global/mixins.scss';

@include mat.core();

$cv-dark-palette: (
  50: #e6eefa,
  100: #c6d5e3,
  200: #a7b8ca,
  300: #879bb2,
  400: #6f869f,
  500: #57728d,
  600: #4a647c,
  700: #3a5066,
  800: #2c3e50,
  900: #1a2a39,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$cv-green-palette: (
  50: #ddf3ee,
  100: #aae2d4,
  200: #6fd0b8,
  300: #1bbc9b,
  400: #00ad87,
  500: #009c74,
  600: #008f68,
  700: #007f59,
  800: #006f4b,
  900: #005230,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$cv-red-palette: (
  50: #f5e8e6,
  100: #f0c9ba,
  200: #e7a68f,
  300: #df8663,
  400: #da6d43,
  500: #d55825,
  600: #cb5321,
  700: #be4c1e,
  800: #b0461b,
  900: #983a14,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$lavender-palette: (
  50: $secondary-main-50,
  100: $secondary-main-50,
  200: $secondary-main-50,
  300: $secondary-main-50,
  400: $secondary-main-50,
  500: $secondary-main-50,
  600: $secondary-main-50,
  700: $secondary-main-50,
  800: $secondary-main-50,
  900: $secondary-main-50,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$black-palette: (
  50: $primary-main-50,
  100: $primary-main-50,
  200: $primary-main-50,
  300: $primary-main-50,
  400: $primary-main-50,
  500: $primary-main-50,
  600: $primary-main-50,
  700: $primary-main-50,
  800: $primary-main-50,
  900: $primary-main-50,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$cv-primary: mat.m2-define-palette($cv-dark-palette, 800);
$cv-accent: mat.m2-define-palette($cv-green-palette, 300);
$cv-warn: mat.m2-define-palette($cv-red-palette, 400);

$lavender-theme: mat.m2-define-palette($lavender-palette, 500);
$black-theme: mat.m2-define-palette($black-palette, 500);

$cv-theme: mat.m2-define-light-theme((
  color: (
    primary: $lavender-theme,
    accent: $black-theme,
    warn: $cv-warn
  )
));

@include mat.all-component-themes($cv-theme);
@include mat.icon-button-density(-3);
@include mat.icon-density(-3);

.form-field-xs {
  @include mat.form-field-density(minimum);
  @include mat.input-density(minimum);

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
    .mat-mdc-floating-label {
      display: block;
    }

    .mat-mdc-form-field-input-control {
      height: 28px;
    }
  }
}

mat-tab-header {
  border-bottom: 1px solid #ccc;
}

//.mdc-text-field--filled:not(.mdc-text-field--disabled),
//.mat-mdc-form-field-focus-overlay {
//  background-color: transparent;
//}
//
//mat-form-field {
//  &:hover,
//  &:focus {
//    .mat-mdc-form-field-focus-overlay {
//      background-color: transparent;
//    }
//  }
//}
//
//.input-create {
//  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
//    background-color: var(--text-color);
//    opacity: 0.3;
//  }
//
//  .mat-mdc-select-value,
//  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
//    font-family: 'Lato';
//    color: var(--text-color);
//  }
//}



.mat-mdc-menu-panel.mat-mdc-menu-panel.icon-picker-menu {
  color: #fff;
  max-width: 100%;
  background: rgb(140 125 125 / 25%);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  .mat-mdc-menu-content {
    width: 370px;
    max-width: 100%;
    padding: 10px 15px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.emoji-menu {
  width: 340px;
  max-width: 100%;
}

.user-tag {
  font-weight: $font-weight-medium;
}



