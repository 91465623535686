.btn {
    height: 45px;
    width: 145px;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 50px;
    cursor: pointer;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    transition: all .3s ease-out;
    background-color: transparent;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black-800;
    text-decoration: none;

    &:not(.btn--loading) mat-spinner {
        display: none;
    }

    &--finn {
        font-size: 12px;
        border-color: #0063fc;
        color: #0063fc;

        &:disabled {
            .mat-icon {
                filter: grayscale(100%);
            }
        }

        &:not(:disabled):hover {
            color: $white-800;
            background: #0063fc;
        }

        .mat-icon {
            color: #0063fc;
            height: 20px;
            width: 20px;
            margin-right: 5px;
        }

        &.btn--filled {
            color: $white-800;
            background: #0063fc;

            .mat-spinner {
                circle {
                    stroke: $white-800;
                }
            }
        }

        .mat-spinner {
            circle {
                stroke: #0063fc;
            }
        }
    }

    &--primary {
        font-size: 12px;
        border-color: $grey-material;

        &:not(:disabled):hover {
            color: $white-800;
            background: $grey-material;
        }

        &.btn--filled {
            color: $white-800;
            background: $grey-material;

            .mat-spinner {
                circle {
                    stroke: $white-800;
                }
            }
        }

        .mat-spinner {
            circle {
                stroke: $grey-material;
            }
        }
    }

    &--primary2 {
        font-size: 12px;
        border-color: $navy-blue;
        color: $navy-blue;

        &:not(:disabled):hover {
            color: $white-800;
            background: $navy-blue;
        }

        &.btn--filled {
            color: $white-800;
            background: $navy-blue;

            .mat-spinner {
                circle {
                    stroke: $white-800;
                }
            }

            &:not(:disabled):hover {
                background: $navy-blue-600;
            }
        }

        .mat-spinner {
            circle {
                stroke: $navy-blue;
            }
        }
    }

    &--success {
        font-size: 12px;
        color: $green;
        border-color: $green;

        &:not(:disabled):hover {
            color: $white-800;
            background: $green;
        }

        &.btn--filled {
            color: $white-800;
            background: $green;

            .mat-spinner {
                circle {
                    stroke: $white-800;
                }
            }

            &:hover {
                background: $green-500;
                border-color: $green-500;
            }
        }

        .mat-spinner {
            circle {
                stroke: $green;
            }
        }
    }

    &--success2 {
        font-size: 12px;
        color: $peachcoral-light;
        border-color: $peachcoral-light;

        &:not(:disabled):hover {
            color: $white-800;
            background: $peachcoral-dark;
        }

        &.btn--filled {
            color: $white-800;
            background: $peachcoral-light;

            .mat-spinner {
                circle {
                    stroke: $white-800;
                }
            }

            &:hover {
                background: $peachcoral-dark;
                border-color: $peachcoral-dark;
            }
        }

        .mat-spinner {
            circle {
                stroke: $peachcoral-light;
            }
        }
    }

    &--action {
        font-size: 12px;
        color: $blue-400;
        border-color: $blue-400;

        &:not(:disabled):hover {
            color: $white-800;
            background: $blue-400;
        }

        &.btn--filled {
            color: $white-800;
            background: $blue-400;

            .mat-spinner {
                circle {
                    stroke: $white-800;
                }
            }

            &:hover {
                background: $navy-blue;
                border-color: $navy-blue;
            }
        }

        .mat-spinner {
            circle {
                stroke: $blue-400;
            }
        }
    }

    &--warning {
        font-size: 12px;
        color: $red;
        border-color: $red;

        &:not(:disabled):hover {
            color: $white-800;
            background: $red;
        }

        &.btn--filled {
            color: $white-800;
            background: $red;

            .mat-spinner {
                circle {
                    stroke: $white-800;
                }
            }

            &:hover {
                background: $red-500;
                border-color: $red-500;
            }
        }

        .mat-spinner {
            circle {
                stroke: $red;
            }
        }
    }

    &--loading {
        span {
            display: none;
        }

        .mat-icon {
            display: none;
        }

        .mat-spinner {
            display: block;
            margin: 0 auto;
        }
    }

    &--small {
        height: 30px;
        width: 100px;
        font-size: 10px;
    }

    &--rect {
        border-radius: 3px;
    }

    &--icon {
        width: 45px;
        font-size: 18px;

        >mat-icon {
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &:disabled {
        cursor: default;

        &:not(.btn--loading) {
            background: $grey-material;
            color: $white;
            border: 1px solid transparent;
        }
    }
}


/* Hide default HTML checkbox */
.switch input {
    display: none;
}

///* The slider */
//.slider {
//    position: absolute;
//    cursor: pointer;
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    background-color: #ccc;
//    -webkit-transition: .4s;
//    transition: .4s;
//}
//
//.slider:before {
//    position: absolute;
//    content: "";
//    height: 26px;
//    width: 26px;
//    left: 4px;
//    bottom: 4px;
//    background-color: white;
//    -webkit-transition: .4s;
//    transition: .4s;
//}
//
//input:checked+.slider {
//    background-color: $accept;
//}
//
//input:focus+.slider {
//    box-shadow: 0 0 1px #2196F3;
//}
//
//input:checked+.slider:before {
//    -webkit-transform: translateX(26px);
//    -ms-transform: translateX(26px);
//    transform: translateX(26px);
//}
//
///* Rounded sliders */
//.slider.round {
//    border-radius: 34px;
//}
//
//.slider.round:before {
//    border-radius: 50%;
//}

.btn.btn--loading .spinner-visible {
  display: block;
}

.btn.btn-auto,
.btn-auto {
  min-width: 145px;
  width: auto;
  padding: 0 16px;
}
