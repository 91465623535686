.apply-component {
    padding-bottom: 100px;

    &--job-ad {
        padding-bottom: 0;
    }

    .job-title {
        margin: 65px 0 45px;
    }

    .job-title {
        &__header {
            font-size: 28px;
            font-weight: 800;
        }

        &__expired {
            font-size: 20px;
        }
    }

    .job-position {
        display: flex;
        flex-direction: column;

        .text {
            font-size: 15px;
        }

        &__title {
            margin-top: 10px;
            color: $black-500;
        }
    }

    .grey-line {
        margin-top: 40px;
        height: 1px;
        background-color: $grey-material;
    }

    .btn-wrapper--top {
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .common-box-list {
        margin-top: 30px;

        .common-box {
            margin-top: 21px;

            &:not(.common-box--video) {
                // max-height: 48px;
                align-items: flex-start;
                // transition: height .3s ease-out;

                // &:hover {
                //     max-height: 500px;
                // }
            }

            .common-box-header {
                height: 300px;
            }

            .files-list {
                z-index: 1339;
                // height: 0;
                // opacity: 0;
                max-height: 0;
                transition: all 1s ease-out;
                overflow: hidden;

                ul {
                    margin: 5px 0;
                    padding: 0;
                    list-style: none;

                    li {
                        margin: 5px 0;
                    }

                    i {
                        color: $red;
                        cursor: pointer;
                    }
                }
            }

            .btn-wrapper {
                width: 80%;
                margin-top: 22px;
                display: flex;
                justify-content: space-between;

                .btn {
                    margin: 0 auto;
                }
            }

            &:hover {
                .files-list {
                    max-height: 500px;
                    // transition: max-height 0.3s ease-in;
                }
            }
        }
    }

    .label-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .label-counter {
        font-size: 14px;
        color: $grey-material;
        margin-left: 5px;
    }

    .sublabel {
        margin-left: 5px;
        font-size: 12px;
    }

    .area-label {
        margin-top: 33px;
        margin-bottom: 10px;
        font-size: 16px;
    }

    .textarea-wrapped {
        margin-top: 20px;
        font-size: 16px;
    }

    .row-cta {
        .btn-wrapper {
            width: 100%;
            margin-top: 28px;
            display: flex;
            justify-content: space-between;
        }
    }

    .job-content {
        padding-top: 60px;

        h2 {
            font-size: 28px;
            padding: 0 18px 0 0;
        }
    }

    .job-time-container {
        padding: 20px 0;

        h2 {
            font-size: 28px;
        }

        .job-time {
            margin-top: 0;
            font-size: 14px;
            font-weight: 800;
            display: flex;
            flex-direction: column;

            span {
                margin: 5px 0;
            }
        }
    }

    .job-company-section {
        padding-bottom: 40px;
    }

    .job-company-row {
        padding: 0;

        h2 {
            font-size: 28px;
            padding-top: 20px;
        }
    }

    @include tablet {
        .qualification {
            padding: 30px;
        }
    }

    @include mobile {
        .qualification {
            padding: 0;
        }

        .btn-wrapper--top {
            flex-direction: column;
            align-items: flex-end;
        }
    }
}

.qualification {
    padding: 30px 90px;

    .title {
        font-size: 18px;
        color: $black-700;
        text-align: center;
    }

    .sub-title {
        font-size: 18px;
        color: $black-700;
        margin-top: 41px;
        text-align: center;
    }

    .common-box-list {
        margin-top: 8px;
        width: 240px;
    }

    .common-box {
        margin-top: 17px;
    }

    .btn-wrapper {
        margin-top: 37px;
        display: flex;
        justify-content: center;
    }

    @include tablet {
        padding: 30px;
    }

    @include mobile {
        padding: 0;

        .sub-title {
            margin: 0;
        }

        .btn-wrapper {
            margin-top: 10px;
        }

        .mat-dialog-title {
            margin: 0;
        }
    }
}
