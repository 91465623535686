//@import '@angular/material/theming';
//// Plus imports for other components in your app.
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//@include mat-core();
//// Define the palettes for your theme using the Material Design palettes available in palette.scss
//// (imported above). For each palette, you can optionally specify a default, lighter, and darker
//// hue.
//$candy-app-primary: mat-palette($mat-grey, 800, A100, A400);
//$candy-app-accent: mat-palette($mat-grey, 800, A100, A400);
//// The warn palette is optional (defaults to red).
//$candy-app-warn: mat-palette($mat-red);
//// Create the theme object (a Sass map containing all of the palettes).
//$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
//// Include theme styles for core and each component used in your app.
//// Alternatively, you can import and @include the theme mixins for each component
//// that you are using.
//@include angular-material-theme($candy-app-theme);

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons-outlined,
.material-icons.material-icons--outlined {
  font-family: 'Material Icons Outlined';
}

.material-icons-two-tone,
.material-icons.material-icons--two-tone {
  font-family: 'Material Icons Two Tone';
}

.material-icons-round,
.material-icons.material-icons--round {
  font-family: 'Material Icons Round';
}

.material-icons-sharp,
.material-icons.material-icons--sharp {
  font-family: 'Material Icons Sharp';
}

mat-form-field {
    width: 100%;
}

.cdk-overlay-container {
    z-index: 1000;
}

.cdk-overlay-dark-backdrop {
    &--no-scroll {
        background: rgba(0, 0, 0, 0.6);
    }
}

mat-dialog-container.mat-dialog-container {
    max-height: calc(100vh - 80px);

    .mat-dialog-content {
      margin: 0;
      padding: 0;
    }
}

snack-bar-container.app-snackbar-container {
    min-width: 70px;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #1bbc9b;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #1bbc9b;
}

.mat-slide-toggle .mat-slide-toggle-ripple {
    max-width: none;
}

// Custom mat-select as icon
.cell--filtered {
    position: relative;

    .mat-select {
        position: absolute;
        visibility: hidden;
    }

    .mat-input-underline,
    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-infix {
        padding: 0;
        margin: 0;
        width: auto;
        border: none;
    }

    .mat-input-subscript-wrapper {
        display: none;
    }

    .mat-form-field-wrapper {
        padding: 0;
    }

    .mat-icon {
        border: none;
        font-size: 16px;
        height: 18px;
        width: 18px;

        &--selected {
            border: 1px solid $green;
            color: $green;
            transition: all .3s ease-out;

            &:hover {
                background: $green;
                color: $white;
            }
        }
    }

    .input-wrapper {
        margin-left: 7px;
    }
}

.mat-radio-ripple,
.mat-ripple-element {
    max-width: none;
}

.mat-tooltip {
    font-family: "Lato", sans-serif;
    white-space: pre-line;

    &.tooltip {
        &--long {
            font-size: 14px;
        }
    }
}

// mat-form-field .mat-form-field {
//     &-underline {
//         position: static;
//     }

//     &-subscript-wrapper {
//         position: static;
//     }
// }
.mat-tabs-nolimit {
  .mat-tab-list {
    max-width: none;
  }
}

.checkbox-common {
  .mat-ripple {
    max-width: none;
  }
}

.cdk-global-scrollblock {
  height: auto;
}

.modal-backdrop-blur {
  backdrop-filter: blur(5px);
}

