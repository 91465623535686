$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 960px;
$desktop-width-lg: 1400px;


@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop-lg {
  @media (min-width: #{$desktop-width-lg}) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}
